import React, { useState } from "react";
import TaskService from "../services/TaskService";
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Container, Form, FormCheck, InputGroup, ListGroup, ListGroupItem, Row } from "react-bootstrap";
import { BsPlus, BsTrashFill } from "react-icons/bs";

const Tasks = () => {
    const service = TaskService();
    let tasks = service.getTasks();
    const incompleteTasks = tasks.filter((t) => !t.completed);
    const completedTasks = tasks.filter((t) => t.completed);
    const [newTaskName, setNewTaskName] = useState("");
    const addItemKeyDown = (e: React.KeyboardEvent) => {
        if (e.key == "Enter") addItemButtonClicked();
    };
    const addItemButtonClicked = () => {
        service.addTask(newTaskName);
        setNewTaskName("");
    };
    const IncompleteTasks = () => {
        if (incompleteTasks.length == 0) return null;

        return (
            <Row>
                <Col>
                    <Card>
                        <CardHeader>
                            <CardTitle>Pending</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <ListGroup className="mb-3">
                                {incompleteTasks.map((task) => (
                                    <ListGroupItem key={task.id} as="li" className="d-flex justify-content-between">
                                        <div className="d-flex align-items-center gap-3">
                                            <FormCheck defaultChecked={task.completed} onClick={() => service.toggleCompleted(task.id)} />
                                            <p className="text-start mb-0">{task.title}</p>
                                        </div>
                                        <Button variant="danger" size="sm" onClick={() => service.removeTask(task)}>
                                            <BsTrashFill />
                                        </Button>
                                    </ListGroupItem>
                                ))}
                            </ListGroup>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        );
    };
    const CompletedTasks = () => {
        if (completedTasks.length == 0) return null;

        return (
            <Row>
                <Col>
                    <Card>
                        <CardHeader>
                            <CardTitle>Completed</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <ListGroup className="mb-3">
                                {completedTasks.map((task) => (
                                    <ListGroupItem key={task.id} as="li" className="d-flex justify-content-between" variant="success">
                                        <div className="d-flex align-items-center gap-3">
                                            <FormCheck defaultChecked={task.completed} onClick={() => service.toggleCompleted(task.id)} />
                                            <p className="text-start mb-0">{task.title}</p>
                                        </div>
                                        <Button variant="danger" size="sm" onClick={() => service.removeTask(task)}>
                                            <BsTrashFill />
                                        </Button>
                                    </ListGroupItem>
                                ))}
                            </ListGroup>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        );
    };

    return (
        <Container className="d-flex flex-column gap-3">
            <Row>
                <Col>
                    <InputGroup>
                        <Form.Control
                            placeholder="Add a new task..."
                            value={newTaskName}
                            onChange={(e) => setNewTaskName(e.target.value)}
                            onKeyDown={async (e) => addItemKeyDown(e)}
                        />
                        <Button variant="outline-success" onClick={async () => addItemButtonClicked()}>
                            <BsPlus />
                        </Button>
                    </InputGroup>
                </Col>
            </Row>
            {IncompleteTasks()}
            {CompletedTasks()}
        </Container>
    );
};

export default Tasks;
