import { fetchWeatherApi } from "openmeteo";
import { WeatherDataCurrent, WeatherDataDaily, WeatherDataHourly, WeatherDataUnits } from "../data/weather/WeatherData";
import {
    WEATHER_FORECAST_DAILY_PARAMETERS,
    WEATHER_FORECAST_HOURLY_PARAMETERS,
    getPrecipitationUnit,
    getTemperatureUnit,
    getWindSpeedUnit,
} from "../data/weather/WeatherForecastParameters";
import WeatherData from "../data/weather/WeatherData";
import LocalStorageKeys from "../data/LocalStorageKeys";

export interface WeatherProps {
    latitude: number | null;
    longitude: number | null;
    forecastDays: number;
    units: WeatherDataUnits;
    current: string[];
    hourly: string[];
    daily: string[];
}

class ForecastResponseCurrent {
    time: Date | null = null;
    temperature: number = -1;
    weatherCode: number = -1;
    windSpeed: number | null = null;
    windDirection: number | null = null;

    constructor(time: Date) {
        this.time = time;
    }
}

class ForecastResponseHourly {
    time: Date[] = [];
    temperature: Float32Array = new Float32Array();
    temperatureApparent: Float32Array | null = null;
    humidityRelative: Float32Array | null = null;
    cloudCover: Float32Array | null = null;
    weatherCode: Float32Array = new Float32Array();
    windSpeed: Float32Array | null = null;
    windDirection: Float32Array | null = null;
    precipitation: Float32Array | null = null;
    precipitationProbability: Float32Array | null = null;
    visibility: Float32Array | null = null;

    constructor(time: Date[]) {
        this.time = time;
    }
}

class ForecastResponseDaily {
    time: Date[] = [];
    temperatureMin: Float32Array = new Float32Array();
    temperatureMax: Float32Array = new Float32Array();
    temperatureApparentMin: Float32Array | null = null;
    temperatureApparentMax: Float32Array | null = null;
    weatherCode: Float32Array = new Float32Array();
    windSpeedMax: Float32Array | null = null;
    windDirectionDominant: Float32Array | null = null;
    precipitationHours: Float32Array | null = null;
    precipitationProbabilityMin: Float32Array | null = null;
    precipitationProbabilityMax: Float32Array | null = null;
    sunrise: Float32Array | null = null;
    sunset: Float32Array | null = null;

    constructor(time: Date[]) {
        this.time = time;
    }
}

class WeatherService {
    props: WeatherProps;
    hourly: WeatherDataHourly[];
    daily: WeatherDataDaily[];

    constructor() {
        const storedProps = localStorage.getItem(LocalStorageKeys.WeatherForecastProps);
        this.props = storedProps ? JSON.parse(storedProps) : null;

        const storedHourlyData = localStorage.getItem(LocalStorageKeys.WeatherForecastHourly);
        this.hourly = storedHourlyData ? JSON.parse(storedHourlyData) : null;

        const storedDailyData = localStorage.getItem(LocalStorageKeys.WeatherForecastDaily);
        this.daily = storedDailyData ? JSON.parse(storedDailyData) : null;
    }

    getWeather = async (props: WeatherProps): Promise<WeatherData> => {
        if (!props.latitude || !props.longitude) {
            return {
                units: props.units,
                current: new WeatherDataCurrent(-1, -1, -1, -1),
                hourly: [],
                daily: [],
            };
        }

        const useCachedHourly =
            this.hourly &&
            this.hourly.length > 0 &&
            this.hourly[0].dateShort === new Date().toLocaleDateString() &&
            this.hourly[0].hour == new Date().getHours() &&
            this.props &&
            this.props.latitude == props.latitude &&
            this.props.longitude == props.longitude;
        const useCachedDaily =
            this.daily &&
            this.daily.length > 0 &&
            this.daily.length === props.forecastDays &&
            this.daily[0].dateShort === new Date().toLocaleDateString() &&
            this.props &&
            this.props.latitude == props.latitude &&
            this.props.longitude == props.longitude;

        localStorage.setItem(LocalStorageKeys.WeatherForecastProps, JSON.stringify(props));

        const url = "https://api.open-meteo.com/v1/forecast";
        const responses = await fetchWeatherApi(url, {
            latitude: props.latitude,
            longitude: props.longitude,
            forecast_days: props.forecastDays,
            current: props.current.join(","),
            hourly: props.hourly.join(","),
            daily: props.daily.join(","),
        });

        // Helper function to form time ranges
        const range = (start: number, stop: number, step: number) => Array.from({ length: (stop - start) / step }, (_, i) => start + i * step);

        // Process first location. Add a for-loop for multiple locations or weather models
        const response = responses[0];

        // Attributes for timezone and location
        const utcOffsetSeconds = response.utcOffsetSeconds();

        const current = response.current()!;
        const hourly = response.hourly()!;
        const daily = response.daily()!;

        const weatherData = {
            units: props.units,
            current: new ForecastResponseCurrent(new Date((Number(current.time()) + utcOffsetSeconds) * 1000)),
            hourly: new ForecastResponseHourly(
                range(Number(hourly.time()), Number(hourly.timeEnd()), hourly.interval()).map((t) => new Date((t + utcOffsetSeconds) * 1000))
            ),
            daily: new ForecastResponseDaily(
                range(Number(daily.time()), Number(daily.timeEnd()), daily.interval()).map((t) => new Date((t + utcOffsetSeconds) * 1000))
            ),
        };

        props.current.forEach((key, index) => {
            switch (key) {
                case WEATHER_FORECAST_HOURLY_PARAMETERS.temperature:
                    weatherData.current.temperature = current.variables(index)!.value();
                    break;
                case WEATHER_FORECAST_HOURLY_PARAMETERS.weatherCode:
                    weatherData.current.weatherCode = current.variables(index)!.value();
                    break;
                case WEATHER_FORECAST_HOURLY_PARAMETERS.windSpeed:
                    weatherData.current.windSpeed = current.variables(index)!.value();
                    break;
                case WEATHER_FORECAST_HOURLY_PARAMETERS.windDirection:
                    weatherData.current.windDirection = current.variables(index)!.value();
                    break;
            }
        });

        props.hourly.forEach((key, index) => {
            switch (key) {
                case WEATHER_FORECAST_HOURLY_PARAMETERS.temperature:
                    weatherData.hourly.temperature = hourly.variables(index)!.valuesArray()!;
                    break;
                case WEATHER_FORECAST_HOURLY_PARAMETERS.temperatureApparent:
                    weatherData.hourly.temperatureApparent = hourly.variables(index)!.valuesArray();
                    break;
                case WEATHER_FORECAST_HOURLY_PARAMETERS.humidityRelative:
                    weatherData.hourly.humidityRelative = hourly.variables(index)!.valuesArray();
                    break;
                case WEATHER_FORECAST_HOURLY_PARAMETERS.cloudCover:
                    weatherData.hourly.cloudCover = hourly.variables(index)!.valuesArray();
                    break;
                case WEATHER_FORECAST_HOURLY_PARAMETERS.weatherCode:
                    weatherData.hourly.weatherCode = hourly.variables(index)!.valuesArray()!;
                    break;
                case WEATHER_FORECAST_HOURLY_PARAMETERS.windSpeed:
                    weatherData.hourly.windSpeed = hourly.variables(index)!.valuesArray();
                    break;
                case WEATHER_FORECAST_HOURLY_PARAMETERS.windDirection:
                    weatherData.hourly.windDirection = hourly.variables(index)!.valuesArray();
                    break;
                case WEATHER_FORECAST_HOURLY_PARAMETERS.precipitation:
                    weatherData.hourly.precipitation = hourly.variables(index)!.valuesArray();
                    break;
                case WEATHER_FORECAST_HOURLY_PARAMETERS.precipitationProbability:
                    weatherData.hourly.precipitationProbability = hourly.variables(index)!.valuesArray();
                    break;
                case WEATHER_FORECAST_HOURLY_PARAMETERS.visibility:
                    weatherData.hourly.visibility = hourly.variables(index)!.valuesArray();
                    break;
            }
        });

        if (!useCachedDaily) {
            props.daily.forEach((key, index) => {
                switch (key) {
                    case WEATHER_FORECAST_DAILY_PARAMETERS.temperatureMin:
                        weatherData.daily.temperatureMin = daily.variables(index)!.valuesArray()!;
                        break;
                    case WEATHER_FORECAST_DAILY_PARAMETERS.temperatureMax:
                        weatherData.daily.temperatureMax = daily.variables(index)!.valuesArray()!;
                        break;
                    case WEATHER_FORECAST_DAILY_PARAMETERS.temperatureApparentMin:
                        weatherData.daily.temperatureApparentMin = daily.variables(index)!.valuesArray();
                        break;
                    case WEATHER_FORECAST_DAILY_PARAMETERS.temperatureApparentMax:
                        weatherData.daily.temperatureApparentMax = daily.variables(index)!.valuesArray();
                        break;
                    case WEATHER_FORECAST_DAILY_PARAMETERS.weatherCode:
                        weatherData.daily.weatherCode = daily.variables(index)!.valuesArray()!;
                        break;
                    case WEATHER_FORECAST_DAILY_PARAMETERS.windSpeedMax:
                        weatherData.daily.windSpeedMax = daily.variables(index)!.valuesArray();
                        break;
                    case WEATHER_FORECAST_DAILY_PARAMETERS.windDirectionDominant:
                        weatherData.daily.windDirectionDominant = daily.variables(index)!.valuesArray();
                        break;
                    case WEATHER_FORECAST_DAILY_PARAMETERS.precipitationHours:
                        weatherData.daily.precipitationHours = daily.variables(index)!.valuesArray();
                        break;
                    case WEATHER_FORECAST_DAILY_PARAMETERS.precipitationProbabilityMin:
                        weatherData.daily.precipitationProbabilityMin = daily.variables(index)!.valuesArray();
                        break;
                    case WEATHER_FORECAST_DAILY_PARAMETERS.precipitationProbabilityMax:
                        weatherData.daily.precipitationProbabilityMax = daily.variables(index)!.valuesArray();
                        break;
                    case WEATHER_FORECAST_DAILY_PARAMETERS.sunrise:
                        weatherData.daily.sunrise = daily.variables(index)!.valuesArray();
                        break;
                    case WEATHER_FORECAST_DAILY_PARAMETERS.sunset:
                        weatherData.daily.sunset = daily.variables(index)!.valuesArray();
                        break;
                }
            });
        }

        return this.transformWeatherData(weatherData, useCachedHourly, useCachedDaily);
    };

    transformWeatherData = (
        weatherData: {
            units: WeatherDataUnits;
            current: ForecastResponseCurrent;
            hourly: ForecastResponseHourly;
            daily: ForecastResponseDaily;
        },
        useCachedHourly: boolean,
        useCachedDaily: boolean
    ): WeatherData => {
        const transformHourlyData = (hourlyData: ForecastResponseHourly) => {
            if (useCachedHourly) {
                return this.hourly;
            } else {
                const now = new Date();
                const data: WeatherDataHourly[] = [];

                for (let i = 0; i < hourlyData.time.length; i++) {
                    const time = hourlyData.time[i];
                    if (time < now) continue;

                    data.push(
                        new WeatherDataHourly(
                            time,
                            hourlyData.temperature[i],
                            hourlyData.temperatureApparent ? hourlyData.temperatureApparent[i] : null,
                            hourlyData.humidityRelative ? hourlyData.humidityRelative[i] : null,
                            hourlyData.cloudCover ? hourlyData.cloudCover[i] : null,
                            hourlyData.weatherCode[i],
                            hourlyData.windSpeed ? hourlyData.windSpeed[i] : null,
                            hourlyData.windDirection ? hourlyData.windDirection[i] : null,
                            hourlyData.precipitation ? hourlyData.precipitation[i] : null,
                            hourlyData.precipitationProbability ? hourlyData.precipitationProbability[i] : null,
                            hourlyData.visibility ? hourlyData.visibility[i] : null
                        )
                    );
                }

                localStorage.setItem(LocalStorageKeys.WeatherForecastHourly, JSON.stringify(data));
                return data;
            }
        };

        const transformDailyData = (dailyData: ForecastResponseDaily) => {
            if (useCachedDaily) {
                return this.daily;
            } else {
                const data: WeatherDataDaily[] = [];

                for (let i = 0; i < dailyData.time.length; i++) {
                    data.push(
                        new WeatherDataDaily(
                            dailyData.time[i],
                            dailyData.temperatureMin[i],
                            dailyData.temperatureMax[i],
                            dailyData.temperatureApparentMin ? dailyData.temperatureApparentMin[i] : null,
                            dailyData.temperatureApparentMax ? dailyData.temperatureApparentMax[i] : null,
                            dailyData.weatherCode[i],
                            dailyData.windSpeedMax ? dailyData.windSpeedMax[i] : null,
                            dailyData.windDirectionDominant ? dailyData.windDirectionDominant[i] : null,
                            dailyData.precipitationHours ? dailyData.precipitationHours[i] : null,
                            dailyData.precipitationProbabilityMin ? dailyData.precipitationProbabilityMin[i] : null,
                            dailyData.precipitationProbabilityMax ? dailyData.precipitationProbabilityMax[i] : null,
                            dailyData.sunrise ? dailyData.sunrise[i] : null,
                            dailyData.sunset ? dailyData.sunset[i] : null
                        )
                    );
                }

                localStorage.setItem(LocalStorageKeys.WeatherForecastDaily, JSON.stringify(data));
                return data;
            }
        };

        const data: WeatherData = {
            units: {
                temperature: getTemperatureUnit(weatherData.units.temperature),
                windSpeed: getWindSpeedUnit(weatherData.units.windSpeed),
                precipitation: getPrecipitationUnit(weatherData.units.precipitation),
            },
            current: new WeatherDataCurrent(
                weatherData.current.temperature,
                weatherData.current.weatherCode,
                weatherData.current.windSpeed,
                weatherData.current.windDirection
            ),
            hourly: transformHourlyData(weatherData.hourly),
            daily: transformDailyData(weatherData.daily),
        };

        return data;
    };
}

export default WeatherService;
