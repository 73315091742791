import { FormEvent, useState } from "react";
import { Button, Form, FormControl } from "react-bootstrap";
import { BsSearch } from "react-icons/bs";
import LocationSearchService, { Location } from "../services/LocationSearchService";

interface LocationSearchProps {
    initialValue: string;
    onChange: (location: Location) => void;
}

const LocationSearch = ({ initialValue, onChange }: LocationSearchProps) => {
    const [search, setSearch] = useState(initialValue);
    const getLocation = async (e: FormEvent, query: string) => {
        e.preventDefault();

        const location = await new LocationSearchService().search(query);
        if (location) {
            setSearch(location.name);
            onChange(location);
        }
    };

    return (
        <Form className="d-flex gap-2 align-items-center" onSubmit={(e) => getLocation(e, search)}>
            <FormControl placeholder="Enter a location..." value={search} onChange={(event) => setSearch(event.target.value)} />
            <Button type="submit">
                <BsSearch />
            </Button>
        </Form>
    );
};

export default LocationSearch;
