import "./App.css";
import { MainLayout } from "./shared/MainLayout";
import { Home, About } from "./Pages";
import { Link, Route, Routes } from "react-router-dom";
import Tasks from "./pages/Tasks";
import Weather from "./pages/Weather";
import MiniGames from "./pages/MiniGames";

function App() {
    return (
        <div className="App">
            <Routes>
                <Route path="/" element={<MainLayout />}>
                    <Route index element={<Home />} />
                    <Route path="tasks" element={<Tasks />} />
                    <Route path="weather" element={<Weather />} />
                    <Route path="mini-games" element={<MiniGames />} />
                    <Route path="about" element={<About />} />

                    {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
                    <Route path="*" element={<NoMatch />} />
                </Route>
            </Routes>
        </div>
    );
}

function NoMatch() {
    return (
        <div>
            <h2>Nothing to see here!</h2>
            <p>
                <Link to="/">Go to the home page</Link>
            </p>
        </div>
    );
}

export default App;
