import { Col, Container, Row } from "react-bootstrap";
import TicTacToe from "../components/mini-games/TicTacToe";

const Home = () => {
    return (
        <Container>
            <Row>
                <Col xl={4}>
                    <TicTacToe size={3} />
                </Col>
            </Row>
        </Container>
    );
};

export default Home;
