export interface Location
{
    name: string;
    fullName: string;
    latitude: number;
    longitude: number;
}

class LocationSearchService {
    search = async (query: string): Promise<Location | null> =>
    {
        const response = await fetch(`https://nominatim.openstreetmap.org/search?q=${query}&format=jsonv2`);
        const result: {
            name: string;
            display_name: string,
            lat: number,
            lon: number
        }[] = await response.json();
        if (result && result.length > 0) {
            return {
                name: result[0].name,
                fullName: result[0].display_name,
                latitude: result[0].lat,
                longitude: result[0].lon
            };
        }

        return null;
    };

    reverse = async (latitude: number, longitude: number): Promise<Location | null> =>
    {
        const response = await fetch(`https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=jsonv2`);
        const result: {
            name: string,
            display_name: string,
            lat: number,
            lon: number
        } = await response.json();
        if (result) {
            return {
                name: result.name,
                fullName: result.display_name,
                latitude: result.lat,
                longitude: result.lon
            };
        }

        return null;
    };
}

export default LocationSearchService;