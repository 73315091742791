import { useState } from "react";
import { Button, Card } from "react-bootstrap";
import { BsCircle, BsXLg } from "react-icons/bs";

interface TicTacToeProps {
    size: number;
}

const TicTacToe = ({ size }: TicTacToeProps) => {
    const [player, setPlayer] = useState(1);
    const [grid, setGrid] = useState(Array(size * size).fill(0));
    const [winner, setWinner] = useState(0);
    const [wins, setWins] = useState([0, 0]);
    const isGameOver = grid.every((s) => s > 0);

    const squareClicked = (move: number) => {
        if (grid[move] > 0) return;

        const updatedGrid = [...grid];
        updatedGrid[move] = player;
        setGrid(updatedGrid);

        if (hasPlayerWon(updatedGrid)) {
            setWinner(player);
            setWins((prev) => {
                if (player == 1) return [prev[0]++, prev[1]];
                else if (player == 2) return [prev[0], prev[1]++];
                else return [0, 0];
            });
            return;
        }

        setPlayer(player == 1 ? 2 : 1);
    };

    const hasPlayerWon = (grid: number[]): boolean => {
        const checkLine = (line: number[], player: number): boolean => {
            return line.every((cell) => cell === player);
        };

        for (let i = 0; i < size; i++) {
            const row = grid.slice(i * size, (i + 1) * size);
            if (checkLine(row, player)) return true;
        }

        for (let i = 0; i < size; i++) {
            let column = [];
            for (let j = 0; j < size; j++) column.push(grid[i + j * size]);

            if (checkLine(column, player)) return true;
        }

        let diag1 = [];
        let diag2 = [];
        for (let i = 0; i < size; i++) {
            diag1.push(grid[i + i * size]);
            diag2.push(grid[size - 1 - i + i * size]);
        }
        if (checkLine(diag1, player) || checkLine(diag2, player)) return true;

        return false;
    };

    const Square = ({ value, onClick }: { value: number; onClick: () => void }) => {
        return (
            <div className="border fs-1 d-flex align-items-center justify-content-center" style={{ width: "33%", aspectRatio: 1 }} onClick={onClick}>
                {value == 1 ? <BsXLg /> : value == 2 ? <BsCircle /> : null}
            </div>
        );
    };

    const showGame = () => {
        return (
            <>
                {Array.from({ length: size }).map((_rowValue, rowIndex) => {
                    return (
                        <div key={`tictactoe_row_${rowIndex}`} className="d-flex flex-nowrap">
                            {Array.from({ length: size }).map((_colValue, colIndex) => {
                                const index = rowIndex * size + colIndex;
                                return <Square key={`tictactoe_square_${index}`} value={grid[index]} onClick={() => squareClicked(index)} />;
                            })}
                        </div>
                    );
                })}
            </>
        );
    };

    const showGameOver = () => {
        return (
            <div className="d-flex flex-column justify-content-center align-items-center gap-3 w-100" style={{ aspectRatio: 1 }}>
                <h1>It's a tie!</h1>
                <h4 className="mb-0">
                    <strong>Score</strong>
                </h4>
                <h3>
                    {wins[0]} - {wins[1]}
                </h3>
                <Button className="mt-3" variant="primary" onClick={restartGame}>
                    Restart
                </Button>
            </div>
        );
    };

    const showWinner = () => {
        return (
            <div className="d-flex flex-column justify-content-center align-items-center gap-3 w-100" style={{ aspectRatio: 1 }}>
                <h1>Player {winner} won!</h1>
                <h4 className="mb-0">
                    <strong>Score</strong>
                </h4>
                <h3>
                    {wins[0]} - {wins[1]}
                </h3>
                <Button className="mt-3" variant="primary" onClick={restartGame}>
                    Restart
                </Button>
            </div>
        );
    };

    const restartGame = () => {
        setGrid(Array(size * size).fill(0));
        setWinner(0);
        setPlayer(1);
    };

    return (
        <Card>
            <Card.Header>
                <Card.Title>Tic Tac Toe</Card.Title>
            </Card.Header>
            <Card.Body>{winner > 0 ? showWinner() : isGameOver ? showGameOver() : showGame()}</Card.Body>
        </Card>
    );
};

export default TicTacToe;
