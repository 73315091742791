import { Card, Table } from "react-bootstrap";
import { WeatherDataDaily, WeatherDataUnits, getWeatherIcon } from "../../data/weather/WeatherData";

interface WeatherForecastDailyProps {
    data: WeatherDataDaily[];
    units: WeatherDataUnits;
}

const WeatherForecastDaily = ({ data, units }: WeatherForecastDailyProps) => {
    if (data.length == 0) return null;

    return (
        <Card>
            <Card.Header>
                <Card.Title>{data.length} Day Forecast</Card.Title>
            </Card.Header>
            <Card.Body>
                <Table hover>
                    <thead>
                        <tr>
                            <th></th>
                            <th></th>
                            <th>High ({units.temperature})</th>
                            <th>Low ({units.temperature})</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((day, index) => {
                            return (
                                <tr key={`weatherDaily${index}`} style={{ width: 300 }}>
                                    <td className="align-middle">{day.day}</td>
                                    <td className="align-middle">
                                        <div className="d-flex align-items-center gap-3">
                                            <div className="fs-2" style={{ width: "2rem" }}>
                                                {getWeatherIcon(day.weatherCode, day.weatherDescription)}
                                            </div>
                                        </div>
                                    </td>
                                    <td className="align-middle">{day.temperatureMax}</td>
                                    <td className="align-middle">{day.temperatureMin}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    );
};

export default WeatherForecastDaily;
