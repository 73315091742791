import { useEffect, useState } from "react";
import WeatherService from "../services/WeatherService";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { WEATHER_FORECAST_DAILY_PARAMETERS, WEATHER_FORECAST_HOURLY_PARAMETERS } from "../data/weather/WeatherForecastParameters";
import WeatherData from "../data/weather/WeatherData";
import WeatherForecastDaily from "../components/weather/WeatherForecastDaily";
import WeatherForecastHourly from "../components/weather/WeatherForecastHourly";
import WeatherForecastCurrent from "../components/weather/WeatherForecastCurrent";
import { useGeolocated } from "react-geolocated";
import LocationSearchService, { Location } from "../services/LocationSearchService";
import LocationSearch from "../components/LocationSearch";

const Weather = () => {
    const [location, setLocation] = useState<Location | null>(null);
    const [weatherData, setWeatherData] = useState<WeatherData | null>(null);
    const [loading, setLoading] = useState(true);
    const [fetching, setFetching] = useState(false);

    useGeolocated({
        positionOptions: {
            enableHighAccuracy: false,
        },
        userDecisionTimeout: 3000,
        onSuccess: (position) => {
            const reverseSearch = async () => {
                const result = await new LocationSearchService().reverse(position.coords.latitude, position.coords.longitude);
                if (result) {
                    setLocation(result);
                    setLoading(false);
                }
            };

            reverseSearch();
        },
        onError: () => setLoading(false),
    });

    useEffect(() => {
        getWeather();
    }, [location]);

    const getWeather = async () => {
        if (fetching) return;
        if (!location) return;

        setFetching(true);

        const weatherProps = {
            latitude: location.latitude,
            longitude: location.longitude,
            forecastDays: 7,
            units: {
                temperature: "celcius",
                windSpeed: "kmh",
                precipitation: "mm",
            },
            current: [
                WEATHER_FORECAST_HOURLY_PARAMETERS.temperature,
                WEATHER_FORECAST_HOURLY_PARAMETERS.weatherCode,
                WEATHER_FORECAST_HOURLY_PARAMETERS.windSpeed,
                WEATHER_FORECAST_HOURLY_PARAMETERS.windDirection,
            ],
            hourly: [
                WEATHER_FORECAST_HOURLY_PARAMETERS.temperature,
                WEATHER_FORECAST_HOURLY_PARAMETERS.temperatureApparent,
                WEATHER_FORECAST_HOURLY_PARAMETERS.humidityRelative,
                WEATHER_FORECAST_HOURLY_PARAMETERS.cloudCover,
                WEATHER_FORECAST_HOURLY_PARAMETERS.weatherCode,
                WEATHER_FORECAST_HOURLY_PARAMETERS.windSpeed,
                WEATHER_FORECAST_HOURLY_PARAMETERS.windDirection,
                WEATHER_FORECAST_HOURLY_PARAMETERS.precipitation,
                WEATHER_FORECAST_HOURLY_PARAMETERS.precipitationProbability,
                WEATHER_FORECAST_HOURLY_PARAMETERS.visibility,
            ],
            daily: [
                WEATHER_FORECAST_DAILY_PARAMETERS.temperatureMin,
                WEATHER_FORECAST_DAILY_PARAMETERS.temperatureMax,
                WEATHER_FORECAST_DAILY_PARAMETERS.temperatureApparentMin,
                WEATHER_FORECAST_DAILY_PARAMETERS.temperatureApparentMax,
                WEATHER_FORECAST_DAILY_PARAMETERS.weatherCode,
                WEATHER_FORECAST_DAILY_PARAMETERS.windSpeedMax,
                WEATHER_FORECAST_DAILY_PARAMETERS.windDirectionDominant,
                WEATHER_FORECAST_DAILY_PARAMETERS.precipitationHours,
                WEATHER_FORECAST_DAILY_PARAMETERS.precipitationProbabilityMin,
                WEATHER_FORECAST_DAILY_PARAMETERS.precipitationProbabilityMax,
                WEATHER_FORECAST_DAILY_PARAMETERS.sunrise,
                WEATHER_FORECAST_DAILY_PARAMETERS.sunset,
            ],
        };

        const result = await new WeatherService().getWeather(weatherProps);
        setWeatherData(result);
        setFetching(false);
    };

    if (loading) {
        return (
            <Container>
                <Row className="d-flex align-items-center justify-content-center">
                    <Spinner />
                </Row>
            </Container>
        );
    }

    const weatherForecast = () => {
        if (!weatherData) return null;

        return (
            <>
                <Col xl={6}>
                    <WeatherForecastCurrent data={weatherData.current} units={weatherData.units} />
                </Col>
                <Col xl={6}>
                    <WeatherForecastHourly data={weatherData.hourly} units={weatherData.units} />
                </Col>
                <Col xl={12}>
                    <WeatherForecastDaily data={weatherData.daily} units={weatherData.units} />
                </Col>
            </>
        );
    };

    return (
        <Container className="gap-2">
            <Row className="gy-3">
                <Col xl={12}>
                    <LocationSearch initialValue={location?.name ?? ""} onChange={(location: Location) => setLocation(location)} />
                </Col>
                {weatherForecast()}
            </Row>
        </Container>
    );
};

export default Weather;
