import { Card, Col, Container, Row } from "react-bootstrap";
import { WeatherDataCurrent, WeatherDataUnits, getWeatherIcon } from "../../data/weather/WeatherData";
import { getNearestWindDirectionAngle } from "../../data/weather/WeatherForecastParameters";
import { BsArrowUpCircle } from "react-icons/bs";

interface WeatherDataCurrentProps {
    data: WeatherDataCurrent;
    units: WeatherDataUnits;
}

const WeatherForecastCurrent = ({ data, units }: WeatherDataCurrentProps) => {
    return (
        <Card className="h-100">
            <Card.Header>
                <Card.Title>Now</Card.Title>
            </Card.Header>
            <Card.Body className="d-flex align-items-center">
                <Container fluid>
                    <Row>
                        <Col xl={6} className="text-center">
                            <h1>{getWeatherIcon(data.weatherCode, data.weatherDescription)}</h1>
                            <h1>
                                {data.temperature}
                                {units.temperature}
                            </h1>
                        </Col>
                        <Col xl={6}>
                            <div>Currently: {data.weatherDescription}</div>
                            <div>
                                Wind Speed: {data.windSpeed} {units.windSpeed}
                            </div>
                            <div className="d-flex align-items-center gap-2">
                                Wind Direction: {data.windDirectionBearing}
                                <span
                                    className="d-block"
                                    style={{ transform: `rotate(${data.windDirection ? getNearestWindDirectionAngle(data.windDirection) - 180 : 0}deg)` }}
                                >
                                    <BsArrowUpCircle />
                                </span>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Card.Body>
        </Card>
    );
};

export default WeatherForecastCurrent;
