import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { BsMoonFill, BsSunFill } from "react-icons/bs";
import { useMediaQuery } from "react-responsive";

interface ThemeToggleProps {
    className?: string | undefined;
}

export const ThemeToggle = (props: ThemeToggleProps) => {
    const systemPrefersDark = useMediaQuery({
        query: "(prefers-color-scheme: dark)",
    });
    const [theme, setTheme] = useState(() => {
        const storedTheme = localStorage.getItem("theme");

        if (storedTheme) {
            document.documentElement.setAttribute("data-bs-theme", storedTheme);
            return storedTheme;
        }

        return systemPrefersDark ? "dark" : "light";
    });

    useEffect(() => {
        if (theme) {
            localStorage.setItem("theme", theme);
            document.documentElement.setAttribute("data-bs-theme", theme);
        }
    }, [theme]);

    return (
        <Button className={props.className} variant="none" onClick={() => setTheme(theme === "light" ? "dark" : "light")}>
            {theme === "light" ? <BsSunFill /> : <BsMoonFill />}
        </Button>
    );
};
