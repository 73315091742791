import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import { Link, Outlet } from "react-router-dom";
import { ThemeToggle } from "../components/ThemeToggle";

export function MainLayout() {
    return (
        <div className="vh-100">
            <Navbar expand="lg" className="bg-body-tertiary">
                <Container>
                    <Navbar.Brand as={Link} to="/">
                        <span>
                            Day<span style={{ color: "var(--bs-red)" }}>Hub</span>
                        </span>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="w-100">
                            <Nav.Link as={Link} to="/tasks">
                                Tasks
                            </Nav.Link>
                            <Nav.Link as={Link} to="/weather">
                                Weather
                            </Nav.Link>
                            <Nav.Link as={Link} to="/mini-games">
                                Mini Games
                            </Nav.Link>
                            <ThemeToggle className="ms-auto" />
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <div className="p-3">
                <Outlet />
            </div>
            <div className="sticky-bottom w-100 bottom border-top bg-body" style={{ maxHeight: 100 }}>
                <div className="container text-body text-end py-3">&copy; 2024 mineral.sw :: All rights reserved.</div>
            </div>
        </div>
    );
}
