import { useEffect, useState } from "react";

export interface TaskItem {
    userId: number;
    id: number;
    title: string;
    completed: boolean;
}

const tasksStorageKey: string = "TaskService_Tasks";

const TaskService = () => {
    const [tasks, setTasks] = useState<TaskItem[]>(() => {
        const storedTasks = localStorage.getItem(tasksStorageKey);
        return storedTasks ? JSON.parse(storedTasks) : [];
    });

    useEffect(() => saveTasks(), [tasks]);

    const getTasks = () => tasks;
    const addTask = (text: string) => {
        const task: TaskItem = {
            userId: 1,
            id: tasks.length + 1,
            title: text,
            completed: false,
        };

        setTasks([...tasks, task]);
    };
    const removeTask = (task: TaskItem) => setTasks(tasks.filter((t) => t.id !== task.id));
    const toggleCompleted = (id: number) => {
        setTasks(tasks.map((task) => (task.id === id ? { ...task, completed: !task.completed } : task)));
    };
    const saveTasks = () => localStorage.setItem(tasksStorageKey, JSON.stringify(tasks));

    return { getTasks, addTask, removeTask, toggleCompleted };
};

export default TaskService;
