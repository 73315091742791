import { ReactNode } from "react";
import { BsArrowUpCircle } from "react-icons/bs";

export const WEATHER_FORECAST_UNITS = {
    temperature: ["celcius", "fahrenheit"],
    windSpeed: ["kmh", "ms", "mph", "kn"],
    precipitation: ["mm", "inch"],
};

export const WEATHER_FORECAST_HOURLY_PARAMETERS = {
    temperature: "temperature_2m",
    temperatureApparent: "apparent_temperature",
    humidityRelative: "relative_humidity_2m",
    cloudCover: "cloud_cover",
    weatherCode: "weather_code",
    windSpeed: "wind_speed_10m",
    windDirection: "wind_direction_10m",
    precipitation: "precipitation",
    precipitationProbability: "precipitation_probability",
    visibility: "visibility",
    isDay: "is_day",
};

export const WEATHER_FORECAST_DAILY_PARAMETERS = {
    temperatureMin: "temperature_2m_min",
    temperatureMax: "temperature_2m_max",
    temperatureApparentMin: "apparent_temperature_min",
    temperatureApparentMax: "apparent_temperature_max",
    weatherCode: "weather_code",
    windSpeedMax: "wind_speed_10m_max",
    windDirectionDominant: "wind_direction_10m_dominant",
    precipitationHours: "precipitation_hours",
    precipitationProbabilityMin: "precipitation_probability_min",
    precipitationProbabilityMax: "precipitation_probability_max",
    sunrise: "sunrise",
    sunset: "sunset",
};

export const getTemperatureUnit = (value: string): string => {
    switch (value) {
        case "celcius":
            return "°C";
        case "fahrenheit":
            return "°F";
        default:
            return "";
    }
};

export const getWindSpeedUnit = (value: string): string => {
    switch (value) {
        case "kmh":
            return "km/h";
        case "ms":
            return "m/s";
        case "mph":
            return "mph";
        case "kn":
            return "knots";
        default:
            return "";
    }
};

export const getWindDirection = (degrees: number): string => {
    const directions = ["N", "NNE", "NE", "ENE", "E", "ESE", "SE", "SSE", "S", "SSW", "SW", "WSW", "W", "WNW", "NW", "NNW"];
    const index = Math.round((degrees % 360) / 22.5) % 16;
    return directions[index];
};

export const getNearestWindDirectionAngle = (degrees: number): number => {
    const roundedValue = Math.round(degrees / 22.5) * 22.5;
    return roundedValue === 360 ? 0 : roundedValue;
};

export const getPrecipitationUnit = (value: string): string => {
    switch (value) {
        case "mm":
            return "mm";
        case "inch":
            return "inches";
        default:
            return "";
    }
};

export const getWeatherCodeString = (weatherCode: number): string => {
    switch (weatherCode) {
        case 0:
            return "Clear";
        case 1:
        case 2:
        case 3:
            return "Overcast";
        case 45:
        case 48:
            return "Fog";
        case 51:
        case 53:
        case 55:
            return "Drizzle";
        case 56:
        case 57:
            return "Freezing Drizzle";
        case 61:
        case 63:
        case 65:
            return "Rain";
        case 66:
        case 67:
            return "Freezing Rain";
        case 71:
        case 73:
        case 75:
            return "Snow";
        case 77:
            return "Light Snow";
        case 80:
        case 81:
        case 82:
            return "Rain Showers";
        case 85:
        case 86:
            return "Snow Showers";
        case 95:
            return "Thunderstorm";
        case 96:
        case 99:
            return "Thunderstorm and hail";
        default:
            return `Unknown (${weatherCode})`;
    }
};
