import { Card, Table } from "react-bootstrap";
import { WeatherDataHourly, WeatherDataUnits, getWeatherIcon } from "../../data/weather/WeatherData";

interface WeatherForecastHourlyProps {
    data: WeatherDataHourly[];
    units: WeatherDataUnits;
}

const WeatherForecastHourly = ({ data, units }: WeatherForecastHourlyProps) => {
    if (data.length == 0) return null;

    const countItemsByDay = (array: WeatherDataHourly[]) => {
        const nth = (d: number) => {
            if (d > 3 && d < 21) return "th";
            switch (d % 10) {
                case 1:
                    return "st";
                case 2:
                    return "nd";
                case 3:
                    return "rd";
                default:
                    return "th";
            }
        };

        const countsByDay: { day: string; count: number }[] = [];

        const sortedArray = array.slice().sort((a: WeatherDataHourly, b: WeatherDataHourly) => new Date(a.date).getTime() - new Date(b.date).getTime());

        // Iterate through the array
        sortedArray.forEach((item) => {
            // Extract the day from the datetime property
            const date = new Date(item.date).getDate();
            const day = date.toString() + nth(date);

            // If the day is not in the countsByDay object, initialize its count to 1
            if (!countsByDay.some((c) => c.day === day)) {
                countsByDay.push({ day: day, count: 1 });
            } else {
                countsByDay.find((c) => c.day === day)!.count++;
            }
        });

        // Return the countsByDay object
        return countsByDay;
    };

    return (
        <Card>
            <Card.Header>
                <Card.Title>Hourly Forecast</Card.Title>
            </Card.Header>
            <Card.Body>
                <Table hover responsive>
                    <thead>
                        <tr>
                            {countItemsByDay(data).map((item: { day: string; count: number }) => {
                                return (
                                    <th key={`day_${item.day}`} colSpan={item.count}>
                                        {item.day}
                                    </th>
                                );
                            })}
                        </tr>
                        <tr>
                            {data.map((item) => {
                                return (
                                    <th key={`hour_${item.dateShort}_${item.hour}`}>
                                        <div style={{ width: 40 }}>{item.hour.toString().padStart(2, "00")}</div>
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {data.map((item) => {
                                return (
                                    <td key={`weatherIcon_${item.dateShort}_${item.hour}`} className="fs-4">
                                        {getWeatherIcon(item.weatherCode, item.weatherDescription)}
                                    </td>
                                );
                            })}
                        </tr>
                        <tr>
                            {data.map((item) => {
                                return <td key={`temperature_${item.dateShort}_${item.hour}`}>{item.temperature}</td>;
                            })}
                        </tr>
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    );
};

export default WeatherForecastHourly;
