import {
    BsCloudDrizzleFill,
    BsCloudFog2Fill,
    BsCloudLightningFill,
    BsCloudLightningRainFill,
    BsCloudRainFill,
    BsCloudRainHeavyFill,
    BsCloudSnowFill,
    BsCloudSunFill,
    BsSunFill,
} from "react-icons/bs";
import { getWeatherCodeString, getWindDirection as getWindDirectionBearing } from "./WeatherForecastParameters";

interface WeatherData {
    units: WeatherDataUnits;
    current: WeatherDataCurrent;
    hourly: WeatherDataHourly[];
    daily: WeatherDataDaily[];
}

export interface WeatherDataUnits {
    temperature: string;
    windSpeed: string;
    precipitation: string;
}

export class WeatherDataCurrent {
    temperature: number | null;
    weatherCode: number;
    weatherDescription: string;
    windSpeed: number | null;
    windDirection: number | null;
    windDirectionBearing: string | null;

    constructor(temperature: number, weatherCode: number, windSpeed: number | null, windDirection: number | null) {
        this.temperature = Math.round(temperature);
        this.weatherCode = weatherCode;
        this.weatherDescription = getWeatherCodeString(weatherCode);
        this.windSpeed = windSpeed ? Math.round(windSpeed) : null;
        this.windDirection = windDirection ? Math.round(windDirection) : null;
        this.windDirectionBearing = windDirection ? getWindDirectionBearing(windDirection) : null;
    }
}

export class WeatherDataHourly {
    date: Date;
    dateShort: string;
    time: string;
    hour: number;
    temperature: number | null;
    temperatureApparent: number | null;
    humidityRelative: number | null;
    cloudCover: number | null;
    weatherCode: number;
    weatherDescription: string;
    windSpeed: number | null;
    windDirection: number | null;
    windDirectionBearing: string | null;
    precipitation: number | null;
    precipitationProbability: number | null;
    visibility: number | null;

    constructor(
        date: Date,
        temperature: number,
        temperatureApparent: number | null,
        humidityRelative: number | null,
        cloudCover: number | null,
        weatherCode: number,
        windSpeed: number | null,
        windDirection: number | null,
        precipitation: number | null,
        precipitationProbability: number | null,
        visibility: number | null
    ) {
        this.date = date;
        this.dateShort = date.toLocaleDateString();
        this.time = date.toLocaleTimeString("en-US");
        this.hour = date.getHours();
        this.temperature = Math.round(temperature);
        this.temperatureApparent = temperatureApparent ? Math.round(temperatureApparent) : null;
        this.humidityRelative = humidityRelative ? Math.round(humidityRelative) : null;
        this.cloudCover = cloudCover ? Math.round(cloudCover) : null;
        this.weatherCode = weatherCode;
        this.weatherDescription = getWeatherCodeString(weatherCode);
        this.windSpeed = windSpeed ? Math.round(windSpeed) : null;
        this.windDirection = windDirection ? Math.round(windDirection) : null;
        this.windDirectionBearing = windDirection ? getWindDirectionBearing(windDirection) : null;
        this.precipitation = precipitation ? Math.round(precipitation) : null;
        this.precipitationProbability = precipitationProbability ? Math.round(precipitationProbability) : null;
        this.visibility = visibility ? Math.round(visibility) : null;
    }
}

export class WeatherDataDaily {
    day: string;
    date: string;
    dateShort: string;
    time: string;
    temperatureMin: number | null;
    temperatureMax: number | null;
    temperatureApparentMin: number | null;
    temperatureApparentMax: number | null;
    weatherCode: number;
    weatherDescription: string;
    windSpeedMax: number | null;
    windDirectionDominant: number | null;
    windDirectionDominantBearing: string | null;
    precipitationHours: number | null;
    precipitationProbabilityMin: number | null;
    precipitationProbabilityMax: number | null;
    sunrise: string | null;
    sunset: string | null;

    constructor(
        date: Date,
        temperatureMin: number,
        temperatureMax: number,
        temperatureApparentMin: number | null,
        temperatureApparentMax: number | null,
        weatherCode: number,
        windSpeedMax: number | null,
        windDirectionDominant: number | null,
        precipitationHours: number | null,
        precipitationProbabilityMin: number | null,
        precipitationProbabilityMax: number | null,
        sunrise: number | null,
        sunset: number | null
    ) {
        this.day = new Intl.DateTimeFormat("en-US", { weekday: "long" }).format(date);
        this.date = date.toDateString();
        this.dateShort = date.toLocaleDateString();
        this.time = date.toLocaleTimeString("en-US");
        this.temperatureMin = Math.round(temperatureMin);
        this.temperatureMax = Math.round(temperatureMax);
        this.temperatureApparentMin = temperatureApparentMin ? Math.round(temperatureApparentMin) : null;
        this.temperatureApparentMax = temperatureApparentMax ? Math.round(temperatureApparentMax) : null;
        this.weatherCode = weatherCode;
        this.weatherDescription = getWeatherCodeString(weatherCode);
        this.windSpeedMax = windSpeedMax ? Math.round(windSpeedMax) : null;
        this.windDirectionDominant = windDirectionDominant ? Math.round(windDirectionDominant) : null;
        this.windDirectionDominantBearing = windDirectionDominant ? getWindDirectionBearing(windDirectionDominant) : null;
        this.precipitationHours = precipitationHours ? Math.round(precipitationHours) : null;
        this.precipitationProbabilityMin = precipitationProbabilityMin ? Math.round(precipitationProbabilityMin) : null;
        this.precipitationProbabilityMax = precipitationProbabilityMax ? Math.round(precipitationProbabilityMax) : null;
        this.sunrise = sunrise ? new Date(sunrise).toLocaleTimeString("en-US") : null;
        this.sunset = sunset ? new Date(sunset).toLocaleTimeString("en-US") : null;
    }
}

export const getWeatherIcon = (weatherCode: number, weatherDescription: string) => {
    const getIcon = (weatherCode: number) => {
        switch (weatherCode) {
            case 0:
                return <BsSunFill />;
            case 1:
            case 2:
            case 3:
                return <BsCloudSunFill />;
            case 45:
            case 48:
                return <BsCloudFog2Fill />;
            case 51:
            case 53:
            case 55:
            case 56:
            case 57:
                return <BsCloudDrizzleFill />;
            case 61:
            case 63:
            case 65:
            case 66:
            case 67:
                return <BsCloudRainHeavyFill />;
            case 71:
            case 73:
            case 75:
            case 77:
                return <BsCloudSnowFill />;
            case 80:
            case 81:
            case 82:
                return <BsCloudRainFill />;
            case 85:
            case 86:
                return <BsCloudSnowFill />;
            case 95:
                return <BsCloudLightningFill />;
            case 96:
            case 99:
                return <BsCloudLightningRainFill />;
            default:
                return null;
        }
    };

    return (
        <div data-bs-toggle="tooltip" data-bs-title={weatherDescription}>
            {getIcon(weatherCode)}
        </div>
    );
};

export default WeatherData;
